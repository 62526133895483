import { Button } from '@/Components/ui/button';
import { Card } from '@/Components/ui/card';
import { navigate } from '@/Hooks/Functions';
import { JSONGetRequest } from '@/Hooks/JSONRequest';
import Loader from '@/Modules/Loader';
import ProductSelectModal from '@/Pages/Designer/components/ProductSelectModal';
import { homeAction, homeState } from '@state/home';
import { bothAction } from '@state/mode-both';
import { useEffect, useState } from "react";
import { Img } from 'react-image';
import { useSnapshot } from 'valtio';


export default function SelectProduct() {

    const snapHome = useSnapshot(homeState);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        JSONGetRequest({
            href: route("categories.paginate", {
                per_page: 10,
                type: "products",
                has_products: 1
            }),
            onResult: (val) => {
                setCategories(val.data);
                setCategory(val.data?.[0]);
            },
            onLoading: (val) => setLoading(val),
        });
    }, []);

    useEffect(() => {
        !!category && JSONGetRequest({
            href: route("products.paginate", {
                per_page: 10,
                fromStore: 1,
                active: 1,
                category_id: category?.id
            }),
            onResult: (val) => setProducts(val.data),
            onLoading: (val) => setLoading(val),
        });
    }, [category]);


    const navigateToEditor = (product) => {
        navigate(route('designer.via-prompt-product', { product_id: product.id, prompt_image_id: snapHome.selectedPromptImage }), "GET");
        bothAction.setToProductMode(false);
        homeAction.setActiveStep(0);
    }

    return <div className="flex flex-col w-full min-h-[400px]">
        <div className="w-full flex-row flex justify-between items-center gap-4">
            <div className='flex flex-1 flex-row gap-4 justify-start items-center'>
                {categories.map(cat => <Button key={cat.id} size="xs" onClick={() => setCategory(cat)}>{cat.name}</Button>)}
            </div>
            <Button size="xs" onClick={() => setShowModal(true)} >View All Products</Button>
        </div>
        {loading && <div className="w-full grid h-96 gap-4 grid-cols-3 justify-center items-center">
            {[1, 2, 3].map((i) => <Loader key={i} />)}
        </div>}
        {!loading && <div className="w-full flex-row py-4 flex justify-center items-center gap-4">
            {products?.map(product => <Card key={product.id} className="w-1/4 overflow-hidden shadow-xl relative">
                <Img className='object-cover' src={product.url_thumb} alt={product.title} />
                <span className='text-sm font-semibold absolute top-4 right-4'>{product.range_price}</span>
                <div className="flex px-4 py-2 flex-col justify-between items-center bg-[#f3f3f3]">
                    <span className='text-sm text-center font-semibold'>{product.title}</span>
                    <Button onClick={() => navigateToEditor(product)} className='w-full mt-1' size="sm">Print To This Product</Button>
                </div>
            </Card>)}
        </div>}

        <ProductSelectModal show={showModal} onSelect={(val) => navigateToEditor(val)} onClose={() => setShowModal(false)} />
    </div>
}
